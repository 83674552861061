import React, { Children } from "react"
import { css } from "@emotion/react"

export interface BtnParam {
  linkto: String
  children: any
  imageIn: Boolean
}

const LinkBtn: React.FC<BtnParam> = ({ linkto, children, imageIn = false }) => {
  const isImage = imageIn ? "image-inner" : ""
  return (
    <div css={btnStyle}>
      <a
        href={linkto}
        target="_blank"
        rel="noopener noreferrer"
        className={isImage}
      >
        {children}
      </a>
    </div>
  )
}

const btnStyle = css`
  text-align: center;
  > a {
    font-weight: 700;
    color: #233333;
    display: inline-block;
    padding: 10px 12px;
    min-width: 200px;
    background: #fff;
    border: 2px solid #c9c9c9;
    text-decoration: none;
    border-radius: 7px;
    transition: all 0.3s ease;

    &.image-inner {
      padding: 15px 18px;
      display: inline-flex;
      align-items: center;
      .image-wrap {
        display: inline-flex;
        align-items: center;
        margin-left: 0.5rem;
      }
    }
  }

  @media (min-width: 767px) {
    > a:hover {
      background: #c9c9c9;
    }
  }

  @media (max-width: 767px) {
    > a {
      font-size: 1em;
      min-width: 165px;
      padding: 8px 11px;
      &.image-inner {
        padding: 10px 14px;
      }
      .image-wrap,
      .gatsby-image-wrapper {
        max-height: 14px;
        height: auto;
        img {
          max-height: 14px;
          height: auto;
        }
      }
    }
  }
`

export default LinkBtn
