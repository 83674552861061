import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';

import data from '../../data/3rd_live.json';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import Section from '../../components/Section';
import SectionTitle from '../../components/SectionTitle';
import LinkBtn from '../../components/LinkBtn';
import ShareButton from '../../components/ShareButton';
import MemberModal from '../../components/MemberModal';

import * as svgPath from '../../images/svgPath';
import * as Styles from '../../styles/styles-3rd';

// style
const IndexPage: React.FC = () => (
  <Layout>
    <Seo
      title="Life Like a Live!3（えるすりー） | イベントアーカイブ"
      description="最先端のバーチャルアイドルONLINEライブフェス『Life Like a Live!』(えるすりー)2020年に開催し4日間で延べ10万人が視聴した伝説のお祭りが帰ってきた！「えるすりー」はオンラインならではの楽しみ方でフェスの臨場感を味わえるイベントです！"
    />
    <section className="mainVisual text-center">
      <StaticImage
        src="../../images/3rd/key_visual.jpg"
        width={1440}
        quality={90}
        alt="Life Like a Live!3（えるすりー）"
        placeholder="none"
        loading="eager"
      />
    </section>

    <Section isWhite={true} addClass={'about_sec'} setCss={Styles.aboutSec}>
      <SectionTitle enText={'ABOUT'} jaText={'イベントについて'} textWhite={false} />
      <p className="text-center" css={Styles.aboutText}>
        世界初・最先端のバーチャルアイドル
        <br className="sp_visible" />
        ONLINEライブフェス
        <br />
        <strong>『Life Like a Live!3』（えるすりー3）</strong>
        今年もバーチャルアイドルが世界を盛り上げます！
        <br />
        第3弾となる「えるすりー3」では、
        <br className="sp_visible" />
        ハイクオリティな音楽ライブだけでなく
        <br className="sp_visible" />
        無料でも楽しめる様々な企画をご用意！
        <br />
        名実共に世界最大のバーチャルアイドルフェスになることを目指して、
        <br className="pc_visible" />
        参加型企画「1000人POLYFULL!大作戦」を決行！
        <br />
        <br />
        クラウドファンディング企画として「えるすりー新・オリジナル曲制作プロジェクト」 を実施するほか、
        <br className="pc_visible" />
        ここでしか見れない3人組ユニット「相思相愛ユニット」計3組によるパフォーマンスも披露！
        <br />
        画面の前でも”熱”を感じられるバーチャルライブをお届けします、お楽しみに！
      </p>

      <StaticImage
        src="../../images/3rd/tr_logo.png"
        width={365}
        alt="Life Like a Live!"
        placeholder="none"
        loading="eager"
      />
    </Section>

    <Section addClass={'event_sec'}>
      <SectionTitle enText={'OUTLINE'} jaText={'イベント概要'} textWhite={true} />

      <dl className="c-white" css={Styles.scheduleDl}>
        <dt>
          <span>開催日程</span>
        </dt>
        <dd>2022年3月25日(金)～3月27日(日)</dd>
      </dl>

      <div css={Styles.platform}>
        <div className="row">
          <div className="col">
            <h3 className="platform-title">
              有料配信
              <br className="sp_visible" />
              プラットフォーム
            </h3>

            <a href="https://www.zan-live.com" target="_blank" rel="noopener noreferrer">
              <div className="image-wrap">
                <StaticImage
                  src="../../images/3rd/z-an_logo.png"
                  width={192}
                  alt="Z-an Logo"
                  placeholder="none"
                  loading="eager"
                />
              </div>
            </a>
          </div>
          <div className="col">
            <h3 className="platform-title">
              無料配信
              <br className="sp_visible" />
              プラットフォーム
            </h3>
            <a
              href="https://www.youtube.com/channel/UC_vFNa_irvWQapwwaasYMOQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image-wrap">
                <img className="platform-logo" src={svgPath.youtubeIcon} alt="Youtube Logo" />
              </div>
            </a>
          </div>
        </div>
      </div>

      <h3 className="c-white" css={Styles.middleTitle}>
        公演概要
      </h3>
      <div css={[Styles.eventSchedule, Styles.mw800]}>
        <ul>
          <li>
            <dl>
              <dt>
                第一公演 2022年3月25日(金) <br className="sp_visible" />
                開場18:00 開演18:30予定
              </dt>
              <dd>
                えのぐ、GEMS COMPANY、VALIS、Palette Project、まりなす、LiLYPSE
                <br />
                射貫まとい＆姫熊りぼん、けものフレンズVぷろじぇくと（コヨーテ、シマハイイロギツネ、フンボルトペンギン）、虹河ラキ、ミライアカリ
                <br />
                相思相愛ユニット（暁みかど、江波キョウカ、NEFFY）
              </dd>
              <dd className="image-wrap">
                <StaticImage
                  src="../../images/3rd/schedule_day1.jpg"
                  width={712}
                  alt="Life Like a Live!3 DAY1 2022.3.25"
                  placeholder="none"
                  loading="eager"
                />
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>
                第二公演 2022年3月26日(土) <br className="sp_visible" />
                開場18:00 開演18:30予定
              </dt>
              <dd>
                えのぐ、GEMS COMPANY（小瀬戸らむ、奈日抽ねね、水科葵）、VALIS（RARA、CHINO、VITTE）、Palette
                Project、まりなす、LiLYPSE
                <br />
                アルリール（白餅だんご、夏羽良イヴ、紫吹真雪）withデジモちゃん、ココロヤミ、天神子兎音、南條夢路＆星輝来夢＆夢瞳カナウ
                <br />
                相思相愛ユニット（暁おぼろ、香鳴ハノン、水科葵）
              </dd>
              <dd className="image-wrap">
                <StaticImage
                  src="../../images/3rd/schedule_day2.jpg"
                  width={712}
                  alt="Life Like a Live!3 DAY2 2022.3.26"
                  placeholder="none"
                  loading="eager"
                />
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>
                第三公演 2022年3月27日(日) <br className="sp_visible" />
                開場18:00 開演18:30予定
              </dt>
              <dd>
                えのぐ、GEMS COMPANY、VALIS、Palette Project、まりなす、LiLYPSE
                <br />
                奏みみ、96猫、東雲めぐ
                <br />
                相思相愛ユニット（鈴鳴すばる、CHINO、長谷みこと）
              </dd>
              <dd className="image-wrap">
                <StaticImage
                  src="../../images/3rd/schedule_day3.jpg"
                  width={712}
                  alt="Life Like a Live!3 DAY3 2022.3.27"
                  placeholder="none"
                  loading="eager"
                />
              </dd>
            </dl>
          </li>
        </ul>
      </div>
      {/* <div css={Styles.mw600}>
          <p css={Styles.caution}>
            <small>
              ※GEMS COMPANY、Palette
              Project、VALIS、まりなす、LiLYPSEは一部のキャストのみの参加となる場合がございます。
            </small>
          </p>
        </div> */}

      <div css={Styles.tickectSection}>
        <h3 className="c-white" css={Styles.middleTitle}>
          チケット
        </h3>
        <div css={[Styles.mw600, Styles.ticketSchedule]}>
          <ul>
            <li>
              <dl>
                <dt>えるすりー3【第一公演】 視聴チケット</dt>
                <dd>
                  価格：<strong>4500pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>えるすりー3【第二公演】 視聴チケット</dt>
                <dd>
                  価格：<strong>4500pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>えるすりー3【第三公演】 視聴チケット</dt>
                <dd>
                  価格：<strong>4500pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>えるすりー3【第一公演】プレミアチケット</dt>
                <dd>
                  価格：<strong>7000pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>えるすりー3【第二公演】プレミアチケット</dt>
                <dd>
                  価格：<strong>7000pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>えるすりー3【第三公演】プレミアチケット</dt>
                <dd>
                  価格：<strong>7000pt</strong>
                </dd>
              </dl>
            </li>

            <li>
              <dl>
                <dt>えるすりー3【通し】視聴チケット</dt>
                <dd>
                  価格：<strong>10000pt</strong>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>えるすりー3【通し】プレミアチケット</dt>
                <dd>
                  価格：<strong>20000pt</strong>
                </dd>
              </dl>
            </li>
          </ul>
        </div>

        <h3 className="c-white" css={Styles.middleTitle}>
          販売サイト
        </h3>
        <ul css={Styles.buttonTop}>
          <li>
            <LinkBtn linkto="https://www.zan-live.com/live/detail/10166" imageIn={true}>
              販売サイトはこちら
              <div className="image-wrap">
                <StaticImage
                  src="../../images/3rd/z-an_logo.png"
                  height={18}
                  alt="Z-an Logo"
                  placeholder="none"
                  loading="eager"
                />
              </div>
            </LinkBtn>
          </li>
          <li>
            <LinkBtn linkto="https://eplus.jp/lifelikealive3/" imageIn={true}>
              販売サイトはこちら
              <div className="image-wrap">
                <StaticImage
                  src="../../images/E_Plus_logo.svg_.png"
                  height={22}
                  alt="Z-an Logo"
                  placeholder="none"
                  loading="eager"
                />
              </div>
            </LinkBtn>
          </li>
        </ul>
      </div>
    </Section>

    <Section isWhite={true} addClass="lineup_sec">
      <SectionTitle enText={'LINE UP'} jaText={'ラインナップ'} />
      <div className="list_lineup" css={Styles.listLineup}>
        <div css={Styles.modalAreaStyle}>
          <div className="container">
            <ul className="row">
              {data.allMicrocmsLineup3.edges[2].node.cast.map((cast, index) => (
                <li className="col" key={cast.fieldId + index}>
                  <MemberModal
                    id={cast.fieldId}
                    castName={cast.name}
                    groupName={cast.groupName}
                    field={cast.url_field}
                    imageUrl={cast.image.url}
                  ></MemberModal>
                </li>
              ))}
            </ul>
          </div>

          <div className="container">
            <ul className="row">
              {data.allMicrocmsLineup3.edges[1].node.cast.map((cast, index) => (
                <li className="col" key={cast.fieldId + index}>
                  <MemberModal
                    id={cast.fieldId}
                    castName={cast.name}
                    groupName={cast.groupName}
                    field={cast.url_field}
                    imageUrl={cast.image.url}
                  ></MemberModal>
                </li>
              ))}
            </ul>
          </div>

          <div className="container">
            <ul className="row">
              {data.allMicrocmsLineup3.edges[0].node.cast.map((cast, index) => (
                <li className="col" key={cast.fieldId + index}>
                  <MemberModal
                    id={cast.fieldId}
                    castName={cast.name}
                    groupName={cast.groupName}
                    field={cast.url_field}
                    imageUrl={cast.image.url}
                  ></MemberModal>
                </li>
              ))}
            </ul>

            {/* <p className="text-center" css={Styles.andMore}>
              and more ...
            </p> */}
          </div>
        </div>
      </div>
    </Section>

    <Section>
      <SectionTitle enText={'GOODS'} jaText={'グッズ'} textWhite />
      <div css={Styles.otherSecStyle}>
        <div className="other-wrap text-center">
          <a
            className="banner-wrapper"
            href="https://www.zan-live.com/capsule/detail/10013"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/3rd/goods_1.jpg"
              width={600}
              alt="Z-aN Capsuleはこちらから"
              placeholder="none"
              loading="eager"
            />
          </a>
        </div>

        <div className="other-wrap text-center">
          <a className="banner-wrapper" href="https://v-demand.com/" target="_blank" rel="noopener noreferrer">
            <StaticImage
              src="../../images/3rd/goods_2.jpg"
              width={600}
              alt="V DeMAND"
              placeholder="none"
              loading="eager"
            />
          </a>
        </div>
      </div>
    </Section>

    <Section addClass="other_sec" isWhite>
      <div css={Styles.otherSecStyle}>
        <SectionTitle enText={'OTHER'} jaText={'関連イベント情報'} />

        <div className="other-wrap text-center">
          <a
            className="banner-wrapper"
            href="https://bridg-e.jp/projects/lll3-project"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/3rd/other_1.jpg"
              width={600}
              alt="みんなでつくる!Life Like a Live!3 新オリジナル曲制作 プロジェクト!"
              placeholder="none"
              loading="eager"
            />
          </a>
        </div>

        <div className="other-wrap text-center">
          <a
            className="banner-wrapper"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeIKlz2OV1e6sre3FPT_w5jRa_1-z4zKOsFmOyMqiV1uTDBUQ/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/3rd/other_2.jpg"
              width={600}
              alt="Life Like a Live!3 1000人POLYFULL大作戦!"
              placeholder="none"
              loading="eager"
            />
          </a>
        </div>

        <div className="other-wrap text-center">
          <a
            className="banner-wrapper"
            href="https://twitter.com/LifeLikeaLive/status/1492737489475280896?s=20&t=5Wu96miOEJIryMYp-FFzNg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/3rd/other_3.jpg"
              width={600}
              alt="相思相愛ユニットアンケート"
              placeholder="none"
              loading="eager"
            />
          </a>
        </div>

        <div className="other-wrap text-center">
          <a
            className="banner-wrapper"
            href="https://lifelikealive.booth.pm/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StaticImage
              src="../../images/3rd/other_4.jpg"
              width={600}
              alt="えるすりーシャッフルユニット2020オリ曲CD"
              placeholder="none"
              loading="eager"
            />
          </a>
        </div>

        <div className="other-wrap text-center">
          <a className="banner-wrapper" href="https://www.mworld.jp/" target="_blank" rel="noopener noreferrer">
            <StaticImage
              src="../../images/3rd/other_5.jpg"
              width={600}
              alt="池袋ミラーワールド"
              placeholder="none"
              loading="eager"
            />
          </a>
        </div>
      </div>
    </Section>

    <Section addClass="twitter_sec">
      <div css={Styles.twitterBlock}>
        <SectionTitle enText={'TWITTER'} jaText={'ツイッター'} textWhite />
        {/* Twitter Timeline */}
        <div className="text-center">
          <a
            className="twitter-timeline"
            data-width="500"
            data-height="500"
            href="https://twitter.com/LifeLikeaLive?ref_src=twsrc%5Etfw"
          >
            Tweets by LifeLikeaLive
          </a>
        </div>
        {/* Twitter Timeline */}
      </div>
    </Section>

    <Section addClass="twitter_sec" isWhite={true}>
      <SectionTitle enText={'CREDIT'} jaText={'クレジット'} textWhite={false} />
      <dl css={Styles.creditList}>
        <dt>主催</dt>
        <dd>バーチャル・エイベックス株式会社 / AVALON</dd>
      </dl>
      <dl css={Styles.creditList}>
        <dt>制作委員会</dt>
        <dd>Life Like a Live!制作委員会</dd>
      </dl>
      <ShareButton />
    </Section>
  </Layout>
);

export default IndexPage;
