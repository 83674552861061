import React from 'react';
import { useState, useCallback } from 'react';
import Modal from 'react-modal';
import { backfaceFixed } from '../../utils/backfaceFixed';

// root nodeのSelectorを設定
Modal.setAppElement('#___gatsby');

export interface ModalParam {
  id: String;
  castName: String;
  groupName: String;
  field?: any;
  imageUrl: String;
}

const MemberModal: React.FC<ModalParam> = ({ id, castName, field, imageUrl, groupName }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const onClickHandler = useCallback(() => {
    setIsOpen(true);
    backfaceFixed(true);
  }, []);

  const closeHandler = useCallback(() => {
    setIsOpen(false);
    backfaceFixed(false);
  }, []);

  return (
    <div className="modal-button-wrap">
      <a className="modal_button" onClick={onClickHandler}>
        <div className="image-wrap">
          <picture>
            <source
              type="image/webp"
              srcSet={`${imageUrl}?w=550&q=75&fm=webp`}
              width="550"
              height="550"
              loading="lazy"
            />
            <img src={`${imageUrl}?w=275&q=75&fm=jpg`} alt={castName} width="275" height="275" loading="lazy" />
          </picture>
        </div>
        <div className="member-name-wrap">
          <p className="member-name">
            {castName}
            {groupName && <small>（{groupName}）</small>}
          </p>
        </div>
      </a>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeHandler}
        onCloseModal={closeHandler}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        overlayClassName={{
          base: 'overlay-base',
          afterOpen: 'overlay-after',
          beforeClose: 'overlay-before'
        }}
        className={{
          base: 'content-base',
          afterOpen: 'content-after',
          beforeClose: 'content-before'
        }}
        closeTimeoutMS={500}
      >
        <div className="modal_wrapper">
          <div className="image-wrapper">
            <p className="cast-name">
              {castName}
              {groupName && <small>（{groupName}）</small>}
            </p>
            <picture>
              <source
                className="cast-image"
                type="image/webp"
                srcSet={`${imageUrl}?w=800&q=75&fm=webp`}
                width="800"
                height="800"
                loading="lazy"
              />
              <img
                className="cast-image"
                src={`${imageUrl}?w=500&q=75&fm=jpg`}
                alt={castName}
                width="500"
                height="500"
                loading="lazy"
              />
            </picture>
          </div>

          {field.map((item, i) => {
            return (
              <dl className={`${item.url_title ? 'profile_dl' : 'profile_dl not_title'}`} key={i}>
                {item.url_title && <dt>{item.url_title}</dt>}
                <dd>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.url}
                  </a>
                </dd>
              </dl>
            );
          })}
          <button type="button" className="modal-close">
            <span>Close</span>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MemberModal;
